
import { initTrackables } from './utilities/trackables';
import { t } from './utilities/I18n.js';

$(function() {
  initPlaceSearch()
  handleContactFormSubmission()
  initEkzLogoPositioning()
  initHeaderPositioning()
  initTrackables()
  initChangeLocaleLinks()
  initSkipLinks()
})


function initPlaceSearch() {
  let timeout;

  $('#place-search-input').val(null);

  $('#place-search-input').typeahead({
    minLength: 2,
    hint: true,
    classNames: {
      selectable: 'nav-item',
      suggestion: 'nav-item',
      menu: 'suggestion-menu col-12 col-lg-6',
      dataset: 'suggestion-box',
      cursor: 'suggestion-active'
    }
  },
  {
    limit: Infinity,
    display: 'zip_and_name',
    name: 'place_search',
    templates: {
      suggestion: placeSearchSuggestionTemplate,
      pending: placeSearchPendingTemplate,
      notFound: placeSearchNotFoundTemplate
    },
      source: function (query, _not_used, callback) {
        const apiPath = $('form#place-search-form').attr('data-api-path');

        if (timeout) clearTimeout(timeout);

        timeout = setTimeout(function () {
          $.getJSON(apiPath, { s: query }, function (data) {
            if (data.length > 0) $('#place-search-input').trigger('typeahead:autocompleted', data.slice(0, 1));
            callback(data);
          })
        }, 400);
      }
  }).on('typeahead:selected', function(event, selected) {
    $('form#place-search-form').attr('action', selected.url).trigger('submit')
  }).on('typeahead:autocompleted', function(event, autocompleted) {
    $('form#place-search-form').attr('action', autocompleted.url)
    $('button#place-search-icon').prop('disabled', false)
  });

  $('#place-search-input').on('focus', function() {
    $('#place-search').addClass('active')
  }).on('blur', function() {
    if (!$(this).val()) $('#place-search').removeClass('active')
  });

  $('#place-search-input').trigger('focus');
}

const placeSearchSuggestionTemplate = (data) => {
  return `<li class="nav-item"><a class="nav-link" href="${data.url}">${data.zip_and_name}<span class="nav-arrow"><svg data-name="Arrow " xmlns="http://www.w3.org/2000/svg" viewBox="0 0 19.3 31.93"><polyline points="12.63 15.96 0 28.59 3.34 31.93 19.3 15.96 3.34 0 0 3.34"/></svg></span></a></li>`
}

const placeSearchPendingTemplate = () => {
  return `<li class="nav-item"><div class="pending">...</span></li>`
}

const placeSearchNotFoundTemplate = () => {
  return `<li class="nav-item"><div class="no-result">${t('frontend.pages.home.search.notfound')}</span></li>`
}

function handleContactFormSubmission() {
  $('form#contact-form')
    .on('submit', function() {
      $(this).find('span.errors').text('');
    })
    .on('ajax:success', function(event) {
      const [data, status, xhr] = event.originalEvent.detail;
      const errors =  data.errors;

      if (errors) {
        for (const field in errors) {
          $('form#contact-form').find(`#errors-${field}`).text(errors[field].join("\n"))
        }
      } else {
        $('form#contact-form').fadeOut('fast');
        $('.form-submitted').fadeIn('slow');
      }
    })
    .on('ajax:error', function(event) {
      const [data, status, xhr] = event.originalEvent.detail;
      const errors =  data.errors;

      for (const field in errors) {
        $('form#contact-form').find(`#errors-${field}`).text(errors[field].join("\n"))
      }
      $('form#contact-form').find(`.invalid-feedback`).show()
    })
}

function initEkzLogoPositioning() {
  const ekzLogoSticky = $('.ekz-logo-sticky');
  const ekzLogoFloaty = $('.ekz-logo-floaty');
  const ekzLogoFooter = $('.ekz-logo-footer');

  positionEkzLogo(ekzLogoSticky, ekzLogoFloaty, ekzLogoFooter)
  $(window).on('scroll', function() { positionEkzLogo(ekzLogoSticky, ekzLogoFloaty, ekzLogoFooter) })
  $(window).on('resize', function() { positionEkzLogo(ekzLogoSticky, ekzLogoFloaty, ekzLogoFooter) })
}

function positionEkzLogo(ekzLogoSticky, ekzLogoFloaty, ekzLogoFooter) {
  if ($(ekzLogoSticky).length < 1 || $(ekzLogoFloaty).length < 1 || $(ekzLogoFooter).length < 1) return;

  if (ekzLogoFooter.position().top < $(window).height()) {
    $(ekzLogoSticky).css('visibility', 'hidden')
    $(ekzLogoFloaty).css('visibility', 'hidden')
  } else {
    $(ekzLogoSticky).css('visibility', 'visible')
    $(ekzLogoFloaty).css('visibility', 'visible')
  }

  if ($(window).scrollTop() > 100) {
    $(ekzLogoSticky).addClass('slide-out')
    $(ekzLogoFloaty).addClass('slide-out')
  } else {
    $(ekzLogoSticky).removeClass('slide-out')
    $(ekzLogoFloaty).removeClass('slide-out')
  }
}

function initHeaderPositioning() {
  let lastScrollTop = $(window).scrollTop()
  const header = $('header')

  $(window).on('scroll', function(){
    const scrollTop = $(this).scrollTop()
    if (scrollTop > lastScrollTop){
        // scrolling down
        if (scrollTop > $(header).height()) $(header).css('transition', 'top 0.25s ease-out').css('top', -$(header).height())
        else $(header).css('transition', 'unset').css('top', -scrollTop)
    } else {
      // scrolling up
      $(header).css('transition', 'top 0.25s ease-out').css('top', 0)
    }
    lastScrollTop = scrollTop;
  });
}

// Update the href of a link to the current path.
// Needed to change locale when the current location has been changed via js.
const baseHref = window.location.href;

function updateUrlPath($elLink) {
  // if url was not changed by js, we have nothing to do here
  if(baseHref != window.location.href) {
    var newBaseUrl = new URL($($elLink).attr('href'));
    const currentUrl = new URL(window.location.href);

    // take the locale part of the link's href (i.e. "/de"),
    // append the rest of the current url (i.e. "8004-zurich/mobility/business")
    // ATTENTION: This works only if the changed (i.e. current) path does not contain translated parts!
    // ds, 15.12.2020
    newBaseUrl.pathname = newBaseUrl.pathname.substring(0,3) + currentUrl.pathname.substring(3);

    $($elLink).attr('href', newBaseUrl);
  }

  return true;
}

function initChangeLocaleLinks() {
  $('.change-locale-link').on('click', function() {
    return updateUrlPath($(this));
  });
}

function initSkipLinks() {
  $('a#skipToNav').on('click', function() {
    $('#navmenu-toggler').trigger('click')
  })
  $('a#skipToSearch').on('click', function() {
    $('#place-search-label').trigger('click')
  })
}
