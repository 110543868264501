
import { empty } from "svelte/internal";
import UrlHelper from "../helpers/UrlHelper";

export const getDetailsParam = () => {
  const urlHelper = new UrlHelper();

  return urlHelper.getSearchParam('details');
}

export const hasDetailsParamValue = (value) => {
  if (!value) return;

  let values = getDetailsParam();
  values = values ? values.split(',') : [];

  return values.includes(value);
}

export const appendDetailsParam = (value) => {
  const urlHelper = new UrlHelper();

  let details = urlHelper.getSearchParam('details');
  let values = details ? details.split(',') : [];
  values = [...values, value];

  urlHelper.setSearchParam('details', values.join(','));
  urlHelper.replaceHistoryState();
}

export const removeValueFromDetailsParam = (value) => {
  const urlHelper = new UrlHelper();

  let details = urlHelper.getSearchParam('details');
  let values = details ? details.split(',') : [];
  values = values.filter(element => element !== value)

  if (values.length < 1) {
    urlHelper.clearSearchParam('details');
  } else {
    urlHelper.setSearchParam('details', values.join(','));
  }
  urlHelper.clearHash();
  urlHelper.replaceHistoryState();
}

export const getHash = () => {
  const urlHelper = new UrlHelper();

  return urlHelper.getHash();
}

export const setHash = (value) => {
  const urlHelper = new UrlHelper();

  urlHelper.setHash(value);
  urlHelper.replaceHistoryState();
}

export const clearHash = () => {
  const urlHelper = new UrlHelper();

  urlHelper.clearHash();
  urlHelper.replaceHistoryState();
}

export const updateSectorAndKind = (sector, kind) => {
  if (!sector || !kind) return;

  const urlHelper = new UrlHelper();
  let pathname = urlHelper.getPathname();
  const path = pathname.split('/');

  path.splice(path.length - 2, 1, sector);
  path.splice(path.length - 1, 1, kind);
  pathname = path.join('/');

  urlHelper.setPathname(pathname);
  urlHelper.clearSearchParam('details');
  urlHelper.clearHash();
  urlHelper.replaceHistoryState();
}
