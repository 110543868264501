<script>
  import { onMount, createEventDispatcher } from "svelte";
  import { fade } from "svelte/transition";

  import { t } from "../utilities/I18n.js";
  import { anyPropertyContains } from "../utilities/search";
  import { setHash, clearHash } from "../utilities/url";
  import { track } from "../utilities/trackables";

  import Subsidy from "./Subsidy.svelte";

  export let field = {},
    searchTerm = "",
    hints_path;

  let subsidies = field.subsidies || [],
    visibleSubsidies,
    id = `category-${field.id}`,
    expanded = false;

  const dispatch = createEventDispatcher();

  const toggleExpandedAll = () => {
    expanded = !expanded;

    for (const subsidy of visibleSubsidies) {
      if (expanded) subsidy.handler.expand();
      else subsidy.handler.collapse();
    }

    updateUrlHash();
  };

  const getVisible = (searchTerm) => {
    let visible = subsidies.filter((subsidy) => {
      subsidy.visible =
        anyPropertyContains(field, ["search_name"], searchTerm) ||
        anyPropertyContains(subsidy, ["search_name", "search_description"], searchTerm) ||
        anyPropertyContains(subsidy.contributor, ["search_name"], searchTerm);

      return subsidy.visible;
    });

    return visible;
  };

  const updateSubsidiesCount = (visibleSubsidies) => {
    field.subsidiesCount = visibleSubsidies.length;
    dispatch("count");
  };

  const updateUrlHash = () => {
    if (expanded) setHash(id);
    else clearHash();
  };

  const triggerTrackable = () => {
    track({ type: "field", key: `${field.kind.substr(0, 1)}.${field.key}`, name: field.name });
  };

  $: visibleSubsidies = getVisible(searchTerm);
  $: updateSubsidiesCount(visibleSubsidies);
</script>

{#if field.subsidiesCount > 0}
  <div class="col-md-10">
    <div {id} data-id={field.id} class="subsidy-field" transition:fade={{ duration: 750 }}>
      <h3>{field.name}</h3>
      <button
        class="btn collapsed btn-details btn-details-float trackable d-print-none"
        aria-expanded={expanded}
        aria-label={expanded ? t("frontend.pages.subsidies.less") : t("frontend.pages.subsidies.more")}
        on:click={toggleExpandedAll}>
        <span class="btn-details-text">
          {#if expanded}
            <svg
              data-name="Menu close"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 31.93 4.72"
              width="31.93"
              height="4.72"
              aria-hidden="true"><rect width="31.93" height="4.72" /></svg>
          {:else}
            <svg data-name="Plus" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40.43 40.43" aria-hidden="true">
              <rect y="17.85" width="40.43" height="4.72" /><rect x="17.85" width="4.72" height="40.43" />
            </svg>
          {/if}
        </span>
      </button>
      <table class="subsidies-table table table-borderless table-no-padding">
        <caption class="sr-only">{t("activerecord.models.subsidy", { count: 2 })} {field.name}</caption>
        <thead>
          <tr>
            <th id="th-{field.id}-subsidy" class="th-subsidy col-12 col-md-7">
              {t("frontend.pages.subsidies.table.heading.subsidy")}
            </th>
            <th id="th-{field.id}-contributor" class="th-contributor d-none d-md-table-cell d-print-table-cell col-5">
              {t("frontend.pages.subsidies.table.heading.contributor")}
            </th>
          </tr>
        </thead>
        {#each subsidies as subsidy (`${field.id}_${subsidy.id}`)}
          <Subsidy
            {subsidy}
            {field}
            {searchTerm}
            bind:this={subsidy.handler}
            on:expand={triggerTrackable}
            {hints_path} />
        {/each}
      </table>
      <div class="row mt-2">
        <button
          class="btn collapsed btn-details trackable d-print-none"
          aria-expanded={expanded}
          on:click={toggleExpandedAll}>
          {expanded ? `– ${t("frontend.pages.subsidies.less")}` : `+ ${t("frontend.pages.subsidies.more")}`}
        </button>
        {#if field.icon}
          <div class="energie-icon field-icon">
            <img src={field.icon} alt="" />
          </div>
        {/if}
      </div>
    </div>
  </div>
{/if}
