import intersection from 'lodash.intersection';
import keys from 'lodash.keys';
import assign from 'lodash.assign';
import omit from 'lodash.omit';

// This module is an adapted version from rails-ujs module
// implemented in http://github.com/reactjs/react-rails
// which is distributed under Apache License 2.0

const ujs = {
  handleEvent(eventName, callback) {
    let _ref = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {
        once: false
      },
      once = _ref.once;
    const $ = typeof window.jQuery !== 'undefined' && window.jQuery;
    if ($) {
      if (once) {
        $(document).one(eventName, callback);
      } else {
        $(document).on(eventName, callback);
      }
    } else {
      document.addEventListener(eventName, callback, {
        once
      });
    }
  },
  setup(onMount, onUnmount) {
    const $ = typeof window.jQuery !== 'undefined' && window.jQuery;
    const _window = window,
      Turbolinks = _window.Turbolinks;

    // Detect which kind of events to set up:
    if (typeof Turbolinks !== 'undefined' && Turbolinks.supported) {
      if (typeof Turbolinks.EVENTS !== 'undefined') {
        // Turbolinks.EVENTS is in classic version 2.4.0+
        this.turbolinksClassic(onMount, onUnmount);
      } else if (typeof Turbolinks.controller !== 'undefined') {
        // Turbolinks.controller is in version 5+
        this.turbolinks5(onMount, onUnmount);
      } else {
        this.turbolinksClassicDeprecated(onMount, onUnmount);
      }
    } else if ($ && typeof $.pjax === 'function') {
      this.pjax(onMount, onUnmount);
    } else {
      this.native(onMount);
    }
  },
  turbolinks5(onMount, onUnmount) {
    this.handleEvent('turbolinks:load', onMount, {
      once: true
    });
    this.handleEvent('turbolinks:render', onMount);
    this.handleEvent('turbolinks:before-render', onUnmount);
  },
  turbolinksClassic(onMount, onUnmount) {
    const _window2 = window,
      Turbolinks = _window2.Turbolinks;
    this.handleEvent(Turbolinks.EVENTS.CHANGE, onMount);
    this.handleEvent(Turbolinks.EVENTS.BEFORE_UNLOAD, onUnmount);
  },
  turbolinksClassicDeprecated(onMount, onUnmount) {
    // Before Turbolinks 2.4.0, Turbolinks didn't
    // have named events and didn't have a before-unload event.
    // Also, it didn't work with the Turbolinks cache, see
    // https://github.com/reactjs/react-rails/issues/87
    const _window3 = window,
      Turbolinks = _window3.Turbolinks;
    Turbolinks.pagesCached(0);
    this.handleEvent('page:change', onMount);
    this.handleEvent('page:receive', onUnmount);
  },
  pjax(onMount, onUnmount) {
    this.handleEvent('ready', onMount);
    this.handleEvent('pjax:end', onMount);
    this.handleEvent('pjax:beforeReplace', onUnmount);
  },
  native(onMount) {
    const $ = typeof window.jQuery !== 'undefined' && window.jQuery;
    if ($) {
      $(() => onMount());
    } else if ('addEventListener' in window) {
      document.addEventListener('DOMContentLoaded', onMount);
    } else {
      // add support to IE8 without jQuery
      window.attachEvent('onload', onMount);
    }
  }
};
const CLASS_ATTRIBUTE_NAME = 'data-svelte-component';
const PROPS_ATTRIBUTE_NAME = 'data-svelte-props';
const noop = () => {};
const WebpackerSvelte = {
  registeredComponents: {},
  render(node, Component) {
    const propsJson = node.getAttribute(PROPS_ATTRIBUTE_NAME);
    const props = propsJson && JSON.parse(propsJson);
    new Component({
      target: node,
      props
    });
  },
  registerComponents(components) {
    const collisions = intersection(keys(this.registeredComponents), keys(components));
    if (collisions.length > 0) {
      console.error(`webpacker-svelte: can not register components. Following components are already registered: ${collisions}`);
    }
    assign(this.registeredComponents, omit(components, collisions));
    return true;
  },
  mountComponents() {
    const registeredComponents = this.registeredComponents;
    const toMount = document.querySelectorAll(`[${CLASS_ATTRIBUTE_NAME}]`);
    for (let i = 0; i < toMount.length; i += 1) {
      const node = toMount[i];
      const className = node.getAttribute(CLASS_ATTRIBUTE_NAME);
      const component = registeredComponents[className];
      if (component) {
        if (node.innerHTML.length === 0) this.render(node, component);
      } else {
        console.error(`webpacker-svelte: can not render a component that has not been registered: ${className}`);
      }
    }
  },
  setup() {
    let components = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    if (typeof window.WebpackerSvelte === 'undefined') {
      window.WebpackerSvelte = this;
      ujs.setup(this.mountComponents.bind(this), noop);
    }
    window.WebpackerSvelte.registerComponents(components);
    window.WebpackerSvelte.mountComponents();
  }
};
export default WebpackerSvelte;