class UrlHelper {
  constructor() {
    this.currentUrl = new URL(window.location.href);
  }

  setSearchParam(parameter, value) {
    if (!parameter || !value) return;
    
    this.currentUrl.searchParams.set(parameter, value);
  }

  getSearchParam(parameter) {
    if (!parameter) return;

    return this.currentUrl.searchParams.get(parameter);
  }

  clearSearchParam(parameter) {
    if (!parameter) return;

    this.currentUrl.searchParams.delete(parameter);
  }

  getHash() {
    return this.currentUrl.hash;
  }

  setHash(hash) {
    if (!hash) return;

    this.currentUrl.hash = hash;
  }

  clearHash() {
    this.currentUrl.hash = '';
  }

  getPathname() {
    return this.currentUrl.pathname;
  }  

  setPathname(pathname) {
    this.currentUrl.pathname = pathname;
  }

  replaceHistoryState() {
    history.replaceState(null, null, this.currentUrl.href);
  }

  pushHistoryState() {
    history.pushState(null, null, this.currentUrl.href);
  }
}

export default UrlHelper;