<script>
  import { tick } from "svelte";
  import { slide, fade } from "svelte/transition";
  import { t } from "../utilities/I18n";

  import iconSearch from "../../images/icons/energie-icons-subsidies-search-fill.svg";

  export let searchTerm = "",
    subsidiesCount = 12;

  let searchActive = false,
    searchInput;

  const toggleSearch = async () => {
    searchActive = !searchActive;
    if (!searchActive) clearSearchTerm();

    await tick();
    if (searchActive) searchInput.focus();
  };

  const clearSearchTerm = () => (searchTerm = "");
</script>

<div class="col-md-10 d-print-none">
  <div class="search-field" class:active={searchActive}>
    <div class="search-box col-md-6 offset-md-3">
      {#if searchActive}
        <div class="search input-group" id="search" aria-label={t("frontend.pages.subsidies.search.label")}>
          <input
            class="search-input form-control"
            type="search"
            aria-labelledby="search"
            placeholder={t("frontend.pages.subsidies.search.placeholder")}
            transition:slide
            bind:value={searchTerm}
            bind:this={searchInput} />
          {#if searchTerm}
            <button
              class="search-clear btn"
              title={t("frontend.pages.subsidies.search.clear")}
              aria-label={t("frontend.pages.subsidies.search.clear")}
              transition:fade={{ duration: 100 }}
              on:click={clearSearchTerm}>
              <svg
                class="icon-clear"
                xmlns="http://www.w3.org/2000/svg"
                width="40.43"
                height="40.43"
                viewBox="0 0 40.43 40.43"
                aria-hidden="true">
                <g transform="rotate(45, 20.2, 20.2)">
                  <rect y="17.85" width="40.43" height="4.72" /><rect x="17.85" width="4.72" height="40.43" />
                </g>
              </svg>
            </button>
          {/if}
          <div class="input-group-append" transition:slide>
            <button
              class="search-submit btn"
              title={t("frontend.pages.subsidies.search.label")}
              aria-labelledby="search">
              <img src={iconSearch} alt="" />
            </button>
          </div>
        </div>
      {/if}
      <div class="counter col-12" class:shiftDown={searchActive}>
        {t("frontend.pages.subsidies.search.counter", { count: subsidiesCount })}
      </div>
    </div>
    <button
      class="search-toggle btn btn-link col-12 col-md-3"
      aria-expanded={searchActive}
      aria-controls="search"
      on:click={toggleSearch}>
      {#if searchActive}
        <span id="search-toggle-label">{t("frontend.pages.subsidies.search.close")}</span>
      {:else}
        <span id="search-toggle-label">{t("frontend.pages.subsidies.search.label")}</span>
      {/if}
      <svg
        class="icon"
        class:flip-Y={!searchActive}
        aria-labelledby="search-toggle-label"
        xmlns="http://www.w3.org/2000/svg"
        width="31.93"
        height="31.93"
        viewBox="0 0 19.3 31.93">
        <polyline
          points="12.63 15.96 0 28.59 3.34 31.93 19.3 15.96 3.34 0 0 3.34"
          transform="rotate(270, 9.65, 15.965)" />
      </svg>
    </button>
  </div>
</div>

<style>
  .search-field {
    display: flex;
    flex-wrap: wrap;
    padding: 10px 5px;
    transition: background-color 0.5s ease;
    background-color: #f4f4f4;
  }
  .search-field.active {
    background-color: #c6c6c6;
    margin-bottom: 0.5rem;
  }
  .search-input {
    font-size: 18px;
    height: 40px;
    position: relative;
    z-index: 1;
  }
  .search-clear {
    position: absolute;
    z-index: 2;
    right: 40px;
    height: 40px;
    padding: 4px;
  }
  .icon-clear {
    height: 80%;
    width: 80%;
    fill: #005e66;
  }

  .search-submit {
    padding: 0;
  }
  .search-toggle {
    height: 40px;
    font-size: 14px;
    margin-left: auto;
    padding: 10px 0;
    z-index: 1;
    white-space: nowrap;
    color: #d8181b;
  }
  .search-toggle .icon {
    fill: #d8181b;
  }

  .search-toggle:hover {
    color: #0056b3;
  }
  .search-toggle:hover .icon {
    fill: #0056b3;
  }
  .search-field .icon {
    height: 1.5em;
  }
  .search-field .icon {
    transition: transform 0.25s ease;
  }
  .counter {
    text-align: center;
    padding-top: 10px;
  }
  .flip-Y {
    -webkit-transform: scaleY(-1);
    transform: scaleY(-1);
  }
</style>
