
import { storageAvailable } from "./storage";

export function initTrackables() {
  window.plausible = window.plausible || function() { (window.plausible.q = window.plausible.q || []).push(arguments) }
}

export function track(trackable) {
  switch(trackable.type) {
    case 'field':
      trackOpenedField(trackable); break;
    case 'print':
      trackPrintButton(); break;
  }
}

function trackOpenedField(trackable) {
  const trackedFields = JSON.parse(sessionStorage.getItem('trackedFields')) || window.trackedFields || []
  const key = trackable.key;
  const name = trackable.name;

  if (!trackedFields.includes(key)) {
    if (storageAvailable('sessionStorage')) sessionStorage.setItem('trackedFields', JSON.stringify([...trackedFields, key]))
    else window.trackedFields = [...trackedFields, key]

    plausible('CategoryDetails', { props: { key, name } })
  }
}

function trackPrintButton() {
  plausible('Printbutton');
}
