
<script>
  import Subsidy from './Subsidy.svelte';

  export let subsidy,
             field;
</script>

<table class="subsidies-table table table-borderless table-no-padding">
  <Subsidy {subsidy} {field} preview />
</table>
