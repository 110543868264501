import I18n from "i18n-js";
import { getLocale } from "./html.js";

I18n.defaultLocale = 'de';
I18n.locale = getLocale();

try {
  require("./translations.js");
} catch (ex) {
  if(process.env.NODE_ENV == 'development') {
    console.log('error loading translations', ex);
  }
}

export default I18n;
export const t = I18n.t;
