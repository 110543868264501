<script>
  import { createEventDispatcher } from 'svelte';
  import { slide } from 'svelte/transition';
  
  import { default as I18n, t } from '../utilities/I18n';
  import { appendDetailsParam, removeValueFromDetailsParam, hasDetailsParamValue, setHash, clearHash } from '../utilities/url';

  export let subsidy,
             field,
             preview = false,
             hints_path,
             displayNotice = true;

  let lang = I18n.locale,
      article_lang = subsidy.locale,
      contributor = subsidy.contributor,
      id = `sub-${field.id}-${subsidy.id}`,
      expanded = preview || hasDetailsParamValue(id),
      addressExpanded = false;

  const dispatch = createEventDispatcher();

  export const expand = () => {
    expanded = true;
    displayNotice = true;
    updateUrlParams();
  }

  export const collapse = () => {
    expanded = false;
    updateUrlParams();
  }
  
  const toggleExpand = () => {
    if (preview) return;

    expanded = !expanded;
    displayNotice = expanded;

    updateUrlParams();
    updateUrlHash();

    if (expanded) dispatch('expand');
  }

  const updateUrlParams = () => {
    if (expanded) appendDetailsParam(id);
    else removeValueFromDetailsParam(id);
  }

  const updateUrlHash = () => {
    if (expanded) setHash(id);
    else clearHash();
  }

  const hideNotice = () => {
    displayNotice = false;
  }
</script>

{#if subsidy.visible || preview}
<tbody {id} lang={article_lang != lang ? article_lang : null} class="subsidy">
  <tr>
    <th id="th-{field.id}-{subsidy.id}" class="subsidy-header d-print-none" colspan="2" scope="rowgroup">
      <button class="subsidy-header-toggle btn" class:expanded
              aria-controls="#detail-{field.id}-{subsidy.id}"
              aria-expanded={expanded}
              on:click={toggleExpand}>
        <span class="subsidy-name col-md-7">
          {@html subsidy.name}
          <span class="nav-arrow d-md-none d-print-none"><svg data-name="Arrow " xmlns="http://www.w3.org/2000/svg" width="19.3" height="31.93" viewBox="0 0 19.3 31.93"><polyline fill="#6D6D6D" points="12.63 15.96 0 28.59 3.34 31.93 19.3 15.96 3.34 0 0 3.34"></polyline></svg></span>
        </span>
        <span class="subsidy-contributor d-none d-md-block col-5">
          {@html contributor.name}
          <span class="nav-arrow d-print-none"><svg data-name="Arrow " xmlns="http://www.w3.org/2000/svg" width="19.3" height="31.93" viewBox="0 0 19.3 31.93"><polyline fill="#6D6D6D" points="12.63 15.96 0 28.59 3.34 31.93 19.3 15.96 3.34 0 0 3.34"></polyline></svg></span>
        </span>
      </button>
    </th>
    <!-- print -->
    <td class="subsidy-name-print d-none d-print-table-cell">
      {@html subsidy.name}
    </td>
    <td class="subsidy-contributor-print d-none d-print-table-cell">
      {@html contributor.name}
    </td>
  </tr>
  {#if expanded}
    <tr id="detail-{field.id}-{subsidy.id}" class="detail-{field.id}-{subsidy.id} subsidy-details">
      <td headers="th-{field.id}-subsidy th-{field.id}-{subsidy.id}" class="col-12 col-md-7 mb-3 description">
        <div class="subsidy-details-content" transition:slide>
          <p>{@html subsidy.description}</p>

          {#if subsidy.site_url}
            <a class="url" href={subsidy.site_url} target="_blank" rel="noopener">{subsidy.site_url_label}</a>
          {/if}
          {#if subsidy.site_url && subsidy.form_url}
            &ndash;
          {/if}
          {#if subsidy.form_url}
            <a class="url" href={subsidy.form_url} target="_blank" rel="noopener">{subsidy.form_url_label}</a>
          {/if}
        </div>
      </td>

      <td headers="th-{field.id}-contributor th-{field.id}-{subsidy.id}" class="contributor d-none d-md-table-cell d-print-table-cell">
        <div class="subsidy-details-content" transition:slide>
          <address id="subsidy-address-{field.id}-{subsidy.id}">
            {@html contributor.address.join("<br/>")}
            {#if contributor.email}
              <br/><a href="mailto:{contributor.email}">{contributor.email}</a>
            {/if}
            {#if contributor.url}
              <br/><a href="{contributor.url}" target="_blank" rel="noopener">{t('frontend.pages.subsidies.address.website')}</a>
            {/if}
          </address>
        </div>
      </td>
    </tr>
    <tr class="d-table-row d-md-none d-print-none">
      <td class="pt-1 pb-4" headers="th-{field.id}-contributor th-{field.id}-{subsidy.id}">
        <div class="subsidy-details-content" transition:slide>
          <button class="btn btn-link btn-address" class:collapsed={!addressExpanded}
                  aria-expanded={addressExpanded}
                  aria-controls="subsidy-address-{field.id}-{subsidy.id}"
                  on:click={() => addressExpanded = !addressExpanded}>
            <span class="collapse-icon">
              <svg xmlns="http://www.w3.org/2000/svg" width="19.3" height="31.93" viewBox="0 0 19.3 31.93"><polyline points="12.63 15.96 0 28.59 3.34 31.93 19.3 15.96 3.34 0 0 3.34"/></svg>
            </span>
            {t('frontend.pages.subsidies.table.heading.contributor')}: {contributor.name}
          </button>
          {#if addressExpanded}
            <address class="collapsible" id="subsidy-address-{field.id}-{subsidy.id}" transition:slide>
              {@html contributor.address.join("<br/>")}
              {#if contributor.email}
              <br/><a href="mailto:{contributor.email}">{contributor.email}</a>
              {/if}
              {#if contributor.url}
                <br/><a href="{contributor.url}" target="_blank" rel="noopener">{t('frontend.pages.subsidies.address.website')}</a>
              {/if}
            </address>
          {/if}
        </div>
      </td>
    </tr>

    {#if displayNotice && !preview}
    <tr>
      <td class="subsidy-notice" colspan="2">
        <div class="subsidy-notice-content" transition:slide>
          <div class="subsidy-notice-icon col-2 col-sm-1">
            <svg xmlns="http://www.w3.org/2000/svg" width="19.75mm" height="19.75mm" viewBox="0 0 55.98 55.98"><circle cx="27.99" cy="27.99" r="27.99" style="fill:#005e66"/><path d="M31.85,41a3,3,0,0,1-.92,2.27,3.51,3.51,0,0,1-2.52.89,3.51,3.51,0,0,1-2.5-.89,3.25,3.25,0,0,1,0-4.56,3.5,3.5,0,0,1,2.49-.89,3.47,3.47,0,0,1,2.49.89A3,3,0,0,1,31.85,41ZM25.34,12.16h6.15V35.09H25.34Z" transform="translate(-0.35 -0.35)" style="fill:#fff"/></svg>
          </div>
          <div class="subsidy-notice-close col-10 col-sm-2 order-sm-12 d-print-none">
            <button class="hide-notice btn" title={t('frontend.pages.subsidies.hide_notice')} aria-label={t('frontend.pages.subsidies.hide_notice')} on:click={hideNotice}>
              <svg class="icon-clear" xmlns="http://www.w3.org/2000/svg" width="40.43" height="40.43" viewBox="0 0 40.43 40.43" aria-hidden="true"><g transform="rotate(45, 20.2, 20.2)" fill="#005E66"><rect y="17.85" width="40.43" height="4.72"/><rect x="17.85" width="4.72" height="40.43" /></g></svg>
            </button>
          </div>
          <div class="subsidy-notice-text col-12 col-sm-9 order-sm-1">
            {@html t('frontend.pages.subsidies.notice_html', { url: hints_path })}
          </div>
        </div>
      </td>
    </tr>
    {/if}
  {/if}
</tbody>
{/if}