

export const anyPropertyContains = (object = {}, properties = Object.keys(object), needle = '', ignoreCase = true, normalize = true) => {
  const haystacks = properties.map(prop => object[prop]).filter(prop => prop);
  
  const result = haystacks.some((haystack) => contains(needle, haystack, ignoreCase, normalize));
  return result;
}

export const contains = (needle = '', haystack = '', ignoreCase = true, normalize = true) => {
  needle = String(needle);
  haystack = String(haystack);

  if (!needle || !haystack) return true;

  // console.debug('needle', needle);

  if (ignoreCase) {
    needle = needle.toLowerCase();
    haystack = haystack.toLowerCase();
  }

  if (normalize) {
    needle = needle.normalize();
    haystack = haystack.normalize();
  }

  return haystack.includes(needle);
}
