<script>
  import { getContext } from "svelte";
  import { t } from "../utilities/I18n";
  import { updateSectorAndKind } from "../utilities/url";

  export let sector, kind;

  const sectorsByKind = getContext("sectorsByKind");
  const kinds = Object.keys(sectorsByKind);

  let kindFocus;

  const resetUrlParams = () => updateSectorAndKind(sector, kind);

  const setDefaultSector = () => {
    if (!sectorsByKind[kind].includes(sector)) {
      sector = sectorsByKind[kind][0];
    }
  };

  $: sectors = sectorsByKind[kind] || [];
  $: sectorToggle = sectors.length == 2;
</script>

<div class="category-selector col-md-10 d-print-none">
  <div class="btn-group btn-group-toggle kind-selector">
    {#each kinds as k}
      <label id="label_{k}" class="btn btn-outline-secondary col" class:active={kind == k} class:focus={kindFocus == k}>
        <input
          id="input_{k}"
          type="radio"
          name="kind"
          value={k}
          data-target="data-kind"
          aria-labelledby="label_{k}"
          bind:group={kind}
          on:change={setDefaultSector}
          on:change={resetUrlParams}
          on:change
          on:focus={() => (kindFocus = k)}
          on:blur={() => (kindFocus = null)} />
        {t(`d.field.kinds.${k}`)}
      </label>
    {/each}
  </div>

  {#if sectorToggle}
    <div class="sector-selector switch">
      <label id="label_{sectors[0]}" for="input_{sectors[0]}" class:active={sector == sectors[0]}>
        {t(`d.field.sectors.${sectors[0]}`)}
      </label>
      <div class="toggle" role="radiogroup">
        <input
          id="input_{sectors[0]}"
          type="radio"
          value={sectors[0]}
          name="sector"
          data-target="data-sector"
          aria-labelledby="label_{sectors[0]}"
          bind:group={sector}
          on:change={resetUrlParams}
          on:change />
        <input
          id="input_{sectors[1]}"
          type="radio"
          value={sectors[1]}
          name="sector"
          data-target="data-sector"
          aria-labelledby="label_{sectors[1]}"
          bind:group={sector}
          on:change={resetUrlParams}
          on:change />
        <div class="indicator" class:right={sector == sectors[1]} />
      </div>
      <label id="label_{sectors[1]}" for="input_{sectors[1]}" class:active={sector == sectors[1]}>
        {t(`d.field.sectors.${sectors[1]}`)}
      </label>
    </div>
  {:else}
    <div class="sector-selector">
      <div class="radio-group" role="radiogroup" class:single={sectors.length < 2}>
        {#each sectors as s}
          <div class="form-check">
            <input
              id="radio_{s}"
              type="radio"
              name="sector"
              value={s}
              data-target="data-sector"
              aria-labelledby="label_{s}"
              class="form-check-input sr-only"
              bind:group={sector}
              on:change={resetUrlParams}
              on:change />
            <label id="label_{s}" for="radio_{s}" class="form-check-label col-xs-12" class:active={sector == s}>
              {t(`d.field.sectors.${s}`)}
            </label>
          </div>
        {/each}
      </div>
    </div>
  {/if}
</div>

<style>
  .category-selector {
    display: flex;
    flex-direction: column;
  }
  .kind-selector {
    gap: 4px;
  }
  .kind-selector .btn-outline-secondary {
    border: 2px solid var(--grey);
    padding: 12px;
    border-radius: 0;
    border-bottom: none;
  }
  .kind-selector .btn-outline-secondary.focus {
    outline: 2px dotted black !important;
    outline-offset: -8px;
    box-shadow: none !important;
  }

  .kind-selector .btn-outline-secondary.active,
  .kind-selector .btn-outline-secondary:hover {
    background-color: var(--grey) !important;
  }
  .sector-selector {
    background-color: var(--grey);
  }
  .sector-selector .radio-group {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    padding: 6px 0;
  }
  .active {
    font-weight: bold;
  }
  .form-check {
    padding-left: 26px;
    padding-right: 0;
  }
  .form-check-label {
    position: relative;
  }
  .form-check-input ~ .form-check-label::before {
    content: "";
    position: absolute;
    width: 20px;
    height: 20px;
    left: -16px;
    top: 8px;
    background-color: white;
    border: 4px solid white;
    border-radius: 50%;
  }

  .radio-group.single .form-check-label::before {
    display: none;
  }

  .form-check-input:focus ~ .form-check-label::before {
    outline: 2px dotted black !important;
    outline-offset: -3px;
  }

  .form-check-input:checked ~ .form-check-label::before {
    background-color: var(--teal);
  }
  .indicator {
    position: absolute;
    top: 10%;
    left: 5%;
    height: 80%;
    width: 40%;
  }
  .indicator.right {
    left: 55%;
  }
</style>
